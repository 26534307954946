@import "../variables.scss";
@import "../animations.scss";

.scroll_up_button {
  position: fixed;
  right: 15px;
  bottom: 15px;
  color: $text-medium-brown;
  cursor: pointer;
  transition: 0.3s;
  z-index: 999;
  &:hover {
    transform: scale(1.1);
  }
}

.scroll_up_button__hidden {
  animation-name: slideOut;
  animation-duration: 0.5s;
  right: -50px;
  // visibility: hidden;
}
.scroll_up_button__visible {
  animation-name: slideIn;
  animation-duration: 0.5s;
  right: 15px;
}