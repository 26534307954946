$background-light: #fffbf2;
$background-dark: #2a2a2a;
// $background-dark: #0a0809;
$text-dark: #0a0809;
$text-light: #e1e5e6;
$text-light-brown: #bca792;
$text-medium-brown: #b09e8b;
$text-dark-brown: #686661;

$xxxxtext: #e1e5e6;
$vcv: #b09e8b;
$logo-background: #f4ddc5;
