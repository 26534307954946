@import "../variables.scss";
@import "../animations.scss";

.header {
  display: flex;
  align-items: center;
  // position: absolute;
  position: fixed;
  background-color: rgba($background-dark, 0.6) !important;
  justify-content: space-between;
  width: 100%;
  min-height: 80px;
  height: auto;
  z-index: 500;
  border-bottom: 1px solid $text-light;

  color: $text-light;
  ul:nth-child(2) {
    animation-name: rollFromLeft;
    animation-duration: 0.8s;
  }
  ul:nth-child(3) {
    padding: 0px 10px;
    margin-right: 30px;
    animation-name: rollFromRight;
    animation-duration: 0.8s;
    display: flex;
    align-items: center;
  }
  ul {
    display: flex;
    gap: 3rem;
    list-style-type: none;

    li {
      z-index: 350 !important;
      display: inline-block;
      position: relative;
      font-family: 'Righteous';
      cursor: pointer;
      font-size: 20px;
      transition: 0.3s;
      &:hover {
        color: $text-light-brown;
      }
    }
    li:after {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      display: block;
      margin-top: 0px;
      right: 0;
      background: $text-light-brown;
      transition: width .5s ease;
    }
    li:hover:after {
      width: 100%;
      left: 0;
      background: $text-light-brown;
    }
  }
}

.no-link {
  @media only screen and (max-width: 1325px) {
    display: none !important;
  }

  z-index: 350 !important;
  display: inline-block;
  position: relative;
  font-family: 'Righteous';
  cursor: default !important;
  font-size: 20px;
  transition: 0.3s;
  &:hover {
    color: $text-light-brown;
  }
}
.no-link:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 0px;
  right: 0;
  background: transparent !important;
  transition: width .5s ease;
}
.no-link:hover:after {
  width: 100%;
  left: 0;
  background: transparent !important;
}
.hidden_on_mobile {
  @media only screen and (max-width: 1325px) {
    display: none !important;
  }
}
.logo__wrap {
  position: absolute;
  left: 50%;
  bottom: -65px;
  margin-left: -64px;
  transition: 0.8s;
  @media only screen and (max-width: 1345px) and (min-width: 1325px) {
    top: 55%;
  }
}
.logo__wrap__hidden {
  position: absolute;
  left: 50%;
  bottom: 400px;
  margin-left: -50px;
  transition: 0.8s;
}
.logo {
  border-radius: 100%;
  border: 1px solid $background-dark;
  width: 120px;
  height: 120px;
  background-color: white;
  padding: 4px;
  transform: scale(0.8);
  z-index: 400 !important;
  cursor: pointer;

  /* position: absolute;
  left: 50%;
  margin-left: -50px;
  top: 25%; */

  /* @media only screen and (max-width: 1345px) and (min-width: 1325px) {
    top: 70%;
  }

  animation-name: rollDown;
  animation-duration: 0.8s;
  @media only screen and (max-width: 1325px) {
    left: 47%;
    width: 120px;
  } */
}
.logo_hidden {
  border-radius: 100%;
  width: 120px;
  background-color: $logo-background;
  padding: 4px;
  transform: scale(0.8);
  z-index: 400;
  // filter: brightness(90%);

  /* position: absolute;
  left: 50%;
  margin-left: -50px;
  top: -300px;
  transform: scale(0.8); */

  /* animation-name: rollUp;
  animation-duration: 0.8s; */
}
.hamburger {
  display: none;
}
.menu {
  transition: 0.8s;
}
.menu_open {
  animation-name: menuOpen;
  animation-duration: 0.8s;
  transition: 0.8s;
  bottom: 0px;
}
.menu_close {
  /* transition: 20s;
  position: relative;
  // top: -100px
  animation-name: menuClose;
  animation-duration: 0.8s; */
}
.header_open {
  height: 220px ;
  transition: 0.8s;
}
.header_close {
  height: 80px;
  transition: 0.8s;
}

@media only screen and (max-width: 1325px) {
  .menu__base {
    position: absolute;
    bottom: 0px;
  }
  .menu_close {
    bottom: 100px;
    transition: 0.8s;
    animation-name: menuClose;
    animation-duration: 4s;
  }
  .menu_close_init {
    bottom: 200px;
    /* transition: 0.8s;
    position: relative;
    top: -200px; */
  }
  .header {
    position: fixed;
    background-color: rgba($color: $background-dark, $alpha: 0.7);
    min-height: 80px;
    ul {
      display: flex;
      flex-direction: column;
      gap: 15px;
      z-index: 1;
    }
  }
  .hamburger {
    position: absolute !important;
    top: 15px;
    right: 0px;
    animation-name: hamburgerAnimation;
    animation-duration: 0.8s;
    width: 100%;
    display: flex;
    justify-content: flex-end;

  }
}

.left_menu_hidden_on_pc {
  display: none !important;
  @media only screen and (max-width: 1325px) {
    display: block !important;
  }
}