@import "../animations.scss";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  inset: 0;
  z-index: 999 !important;

  @media only screen and (max-width: 856px) {
    // display: none;
    img {
      animation-name: galleryImageAnimation;
      animation-duration: 0.3s;
      max-width: 75vw;
      object-fit: contain;
    }
  }

  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  z-index: 999;

  background-color: rgba($color: #000000, $alpha: 0.3);

  div {
    display: flex;
    align-items: center;
  }

  img {
    animation-name: galleryImageAnimation;
    animation-duration: 0.3s;
    max-height: 80vh;
    object-fit: contain;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

}