@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Open+Sans:ital,wght@0,300;0,600;0,700;0,800;1,800&family=Righteous&display=swap&');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Open+Sans:ital,wght@0,300;0,600;0,700;0,800;1,800&family=Righteous&display=swap&');
@import "../variables.scss";

.title_wrap {
  display: flex;
  align-items: center;
  padding: 0px 15px;
}
.title {
  font-family: 'Righteous';
  text-transform: uppercase;
  font-size: 35px;
  @media only screen and (max-width: 856px) {
    font-size: 30px;
  }
}

.editable_input {
  background-color: transparent;
  color: white;
  font-weight: bold;
  padding: 10px 5px;
}
.download_menu_button{
  background-color: $text-light-brown;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  transition: 0.3s;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 10px;
  border-radius: 8px;
  &:active{
    transform: scale(1.05);
  }
}

.download_menu_button_wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.element {
  display: flex;
  justify-content: space-between;
  /* padding-right: 25px;
  padding-bottom: 20px; */
  padding: 5px 15px;

  h1 {
    font-family: 'Righteous';
    line-height: 0px;
    font-weight: 400;
    font-size: 20px;
  }
  h2 {
    line-height: 0px;
    font-size: 13px;
    font-family: 'Righteous';
    font-weight: 400;

  }

  div:nth-child(1) {
    display: flex;
    flex-direction: column;
  }div:nth-child(2) {
    display: flex;
  }
  &:hover {
    cursor: default;
    background-color: $text-dark-brown;
    transition: 0.5s;
    border-radius: 0px;
  }
  @media only screen and (max-width: 856px) {
    h1 {
      font-size: 15px;
    }
  }
}

.element:has(.outline):not(:last-child) {
  border-left: 2px solid grey;
  border-right: 2px solid grey;

}