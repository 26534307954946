@import "../variables.scss";
@import "../animations.scss";

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Open+Sans:ital,wght@0,300;0,600;0,700;0,800;1,800&family=Righteous&display=swap&');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Open+Sans:ital,wght@0,300;0,600;0,700;0,800;1,800&family=Dosis&display=swap&');

.main_view_card {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;

  h1, h2 {
  }

  /* filter: blur(2px);
  -webkit-filter: blur(2px); */


}
.main_view_card_background_image_wrap {
  position: absolute;
  width: 100vw;
  height: auto;
}
.main_view_card_background_image {
  width: 100vw;
  height: 110vh;
  z-index: 1;
  object-fit: cover;
  filter: brightness(40%);
}
.main_view_card__padding {
  padding-top: 20px;
  padding-bottom: 20px;
}

.main_view_card__content {
  min-height: 100vh;
  max-width: 1500px;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  z-index: 100;
}
.main_view_card__brush_top {
  position: absolute;
  width: 100%;
  transform: rotate(180deg);
  z-index: 99;
}

.main_view_card__brush_bottom {
  position: absolute;
  width: 100%;
  bottom: 0px;
  z-index: 99;
}

.main_view_card__dark_bg {
  background-color: $background-dark;
  color: $text-light;
}
.main_view_card__light_bg {
  background-color: $background-light;
  color: $text-dark;
}

.first_card__animation {

  animation-name: scaleUp;
  animation-duration: 0.8s;
  animation-timing-function: ease-out;
}
.first_card {

  img {
    width: 100%;
  }
  text-align: center;
  h1 {
    font-family: 'Righteous';
    font-weight: 400;
    font-size: 130px;
    line-height: 20px;
    color: $text-light
  }

  h2:nth-child(2) {
    font-family: 'Dancing Script';
    font-size: 65px;
    line-height: 0px;
    color: $text-light-brown;
  }
  h2:nth-child(3) {
    font-family: 'Righteous';
    font-size: 25px;
    line-height: 0px;
    color: $text-light;
  }
  @media only screen and (max-width: 856px) {
    img {
      width: 90%;
    }
    h1 {
      font-size: 80px;
      line-height: 10px;
    }

    h2:nth-child(1) {
      font-size: 35px;
      line-height: 25px;
    }
    h2:nth-child(3) {

    }
  }
}

.second_card {
  width: 100%;
  padding: 0% 10%;
}
.second_card__row_one_animation_show {
  animation-name: secondCardAnimationShow;
  animation-duration: 0.8s;
}
.second_card__row_one_animation_hide {
  animation-name: secondCardAnimationHide;
  animation-duration: 0.8s;
  left: -100vw;
}

.second_card__row_one {

  display: flex;
  flex-direction: row;
  position: relative;
  gap: 5%;
  div:nth-child(1) {
    order: 1;
  }
  div:nth-child(2) {
    order: 2;
  }
  h1 {
    font-family: 'Righteous';
    font-size: 50px;
    position: absolute;
    top: 50%;
    left: 20%;
    text-shadow: $background-light 1px 0 15px;
  }
  h2 {
    font-family: 'Righteous';
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    margin-top: 130px;
    // font-family: ;
  }
  img {
    opacity: 0.95;
    max-width: 350px;
  }
  @media only screen and (max-width: 856px) {
    flex-direction: column;

    div:nth-child(1) {
      order: 2;
    }
    div:nth-child(2) {
      order: 1;
    }
    h1 {
      font-size: 40px;
      position: relative;
      top: 0%;
      left: 0%;
    }
    h2 {
      font-size: 22px;
      margin-top: 0px;
      // font-family: ;
    }
    img {
      opacity: 0.95;
      width: 100%;
    }
  }
}

.second_card__row_two_animation_show {
  animation-name: secondCardAnimationShowSecondRow;
  animation-duration: 0.8s;
}
.second_card__row_two_animation_hide {
  animation-name: secondCardAnimationHideSecondRow;
  animation-duration: 0.8s;
  right: -100vw;
}

.second_card__row_two {
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 15%;
  p {
    font-family: 'Righteous';
    margin-top: 130px;
    font-weight:500;
    font-size: 18px;
    text-align: justify;
  }
  h4 {
    font-weight: normal;
    font-size: 32px;
    font-family: 'Righteous';
    line-height: 30px;
  }
  h5 {
    font-family: 'Righteous';
    font-weight: bold;
    line-height: 0px;
  }
  img {
    margin-top: -70px;
    filter:brightness(0.75);
    /* transform: rotate(-90deg); */
    max-width: 400px;
  }
  @media only screen and (max-width: 856px) {
    flex-direction: column;
    gap: 0%;
    p {
      font-family: 'Righteous';
      margin-top: 50px;
      font-weight:500;
      font-size: 18px;
    }
    h4 {
      font-weight: normal;
      font-size: 32px;
      font-family: 'Dancing Script';
      line-height: 30px;
    }
    h5 {
      font-family: 'Righteous';
      font-weight: bold;
      line-height: 0px;
    }
    img {
      margin-top: 0px;
      transform: rotate(0deg);
      max-width: 100% !important;
      padding-bottom: 50px;

    }
  }
}

.third_card__animation {

  animation-name: scaleUp;
  animation-duration: 0.8s;
  animation-timing-function: ease-out;
}
.third_card {
  display: flex;
  align-items: center;
  text-align: center;
  h1 {
    font-family: 'Righteous';
    font-weight: 400;
    font-size: 130px;
    line-height: 20px;
    color: $text-light
  }

  h2:nth-child(1) {
    font-family: 'Dancing Script';
    font-size: 65px;
    line-height: 0px;
    color: $text-light-brown;
  }
  h2:nth-child(3) {
    font-family: 'Righteous';
    font-size: 25px;
    line-height: 0px;
    color: $text-light;
  }
  @media only screen and (max-width: 856px) {
    h1 {
      font-size: 80px;
      line-height: 80px;
      margin-top: -15px;
    }

    h2:nth-child(1) {
      font-size: 35px;
      line-height: 5px;
    }
    h2:nth-child(3) {

    }
  }
}

.fourth_card {
  width: 100%;
  padding: 0% 10%;
}
.fourth_card__row_one {
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 5%;
  div:nth-child(1) {
    order: 1;
  }
  div:nth-child(2) {
    order: 2;
  }
  h1 {
    font-family: 'Righteous';
    font-size: 50px;
    position: absolute;
    top: 50%;
    left: 20%;
    text-shadow: $background-light 1px 0 15px;
  }
  h2 {
    font-family: 'Righteous';
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    margin-top: 130px;
    // font-family: ;
  }
  img {
    opacity: 0.95;
    max-width: 350px;
  }
  @media only screen and (max-width: 856px) {
    flex-direction: column;

    div:nth-child(1) {
      order: 2;
    }
    div:nth-child(2) {
      order: 1;
    }
    h1 {
      font-size: 40px;
      position: relative;
      top: 0%;
      left: 0%;
    }
    h2 {
      font-size: 22px;
      margin-top: 0px;
      // font-family: ;
    }
    img {
      opacity: 0.95;
      width: 100%;
    }
  }
}
.fourth_card__row_two {
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 10%;
  img {
    border-radius: 8px !important;
  }
  p {
    font-family: 'Righteous';
    margin-top: 130px;
    font-weight:500;
    font-size: 18px;
    @media only screen and (max-width: 856px) {
      margin-top: 20px;
    }
  }

  div:nth-child(1) {
    display: flex;
    visibility: hidden;
    flex-direction: column;
    img {
      width: 180px;
      align-self: flex-end;
    }
    @media only screen and (max-width: 856px) {
      img {
        align-self: center;
      }
    }
  }
  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    margin-top: -160px;
    h1 {
      font-family: 'Righteous';
      line-height: 0px;
      font-size: 50px;
      &:nth-child(2) {
        color: $text-dark-brown;
        padding-left: 33px;
        margin-top: 10px;
      }
    }
    img {
      width: 100%;
    }
    @media only screen and (max-width: 856px) {
      h1 {
        font-family: 'Righteous';
        line-height: 10px;
        font-size: 50px;
        &:nth-child(2) {
          color: $text-dark-brown;
        }
      }
    }

  }
  div:nth-child(3) {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    div {
      margin-top: -130px;
    }
    img {
      min-width: 250px;
      width: 100%;
    }
    @media only screen and (max-width: 856px) {
      div {
        margin-top: 20px;
      }
    }
  }
  @media only screen and (max-width: 856px) {
    flex-direction: column;
    gap: 0%;
  }
}
.shop_card_more_content{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  transition: 0.3s;
  &:hover {
    transform: scale(1.05);
  }
  @media only screen and (max-width: 856px) {
    h1 {
      font-size: 28px;
    }
  }
}
.fourth_card__row_three {
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 5%;
  margin-top: -200px;

  margin-left: 150px;

  div:nth-child(1) {
    order: 1;
  }
  div:nth-child(2) {
    order: 2;
    visibility: hidden;
  }
  h1 {
    font-family: 'Righteous';
    font-size: 50px;
    position: absolute;
    top: 50%;
    left: 20%;
    text-shadow: $background-light 1px 0 15px;
  }
  h2 {
    font-family: 'Righteous';
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    margin-top: 130px;
    // font-family: ;
  }
  img {
    opacity: 0.95;
    max-width: 350px;
  }
  @media only screen and (max-width: 856px) {
    flex-direction: column;

    margin-top: 0px;
    margin-left: 0px;
    div:nth-child(1) {
      order: 2;
    }
    div:nth-child(2) {
      order: 1;
    }
    h1 {
      font-size: 40px;
      position: relative;
      top: 0%;
      left: 0%;
    }
    h2 {
      font-size: 22px;
      margin-top: 0px;
      // font-family: ;
    }
    img {
      opacity: 0.95;
      width: 100%;
    }
  }
}

.fifth_card_title_animation_hide {
  animation-name: scaleDown;
  transform: scale(0);
  animation-duration: 0.8s;
}
.fifth_card_title_animation_show {
  animation-name: scaleUp;
  animation-duration: 0.8s;
}
.fifth_card {
  width: 90vw !important;

}
.fifth_card__row_one {
  display: flex;
  flex-direction: column;
  align-items: center;

  left: -100vw !important;
  @media only screen and (max-width: 856px) {
    margin-top: -40px;
  }
  h1 {
    font-family: 'Righteous';
    font-weight: 400;
    font-size: 130px;
    line-height: 0px;
    margin-top: 30px;
    color: $text-dark;
    @media only screen and (max-width: 856px) {
      font-size: 65px;
      margin-top: 0px;
    }
  }

  h2:nth-child(1) {
    font-family: 'Dancing Script';
    font-size: 65px;
    line-height: 0px;
    color: $text-light-brown;
  }
}
.coctail_menu_wrap {
  background-color: red;
  padding: 0px 15px;
  height: 100%;
  width: 50%;
  @media only screen and (max-width: 1185px) {
    width: 100%;
    padding: 0px 0px;
  }
}
.cocktail_menu {
  background-color: orange;
  border-radius: 8px;
  height: 100%;
  display: flex;
  padding: 100px 80px;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1185px) {
    padding: 20px 10px;
  }
}
.cocktail_menu_inner {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.652);
  text-align: center;
  * {
    font-family: 'Righteous';
  }
  h1 {
    line-height: 25px;
  }
  h6 {
    color: #528B85;
  }
}
.cocktail_menu_section_pricetag {
  background-color: orangered;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: absolute;
  margin-left: 25px;

  @media only screen and (max-width: 1185px) {
    margin-left: -30px;
    margin-top: -45px;
  }
}
.cocktail_menu_section {
  margin-top: 30px;
  cursor: default;
  h1 {
    color: #D66960;
    font-size: 25px;
    line-height: 20px;
  }
  small {
    font-size: 12px;
  }
  h3 {
    cursor: pointer;
    color: $background-dark;
    font-size: 18px;
    line-height: 6px;
    transition: 0.3s;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &:hover {
      color: #D66960;
    }
    &:active {
      color: #528B85;
    }
  }
  p {
    color: #528B85;
    font-weight: bold;
    line-height: 5px;
    text-transform: capitalize;
    margin-top: -10px;
  }
}
.fifth_card__cocktail_menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* background-color: $background-dark; */
  width: 100%;
  position: relative;
  /* color: white; */
  /* padding: 15px 10px ; */
  border-radius: 8px;
  img {
    max-width: 50%;
    border-radius: 8px;
  }

  @media only screen and (max-width: 856px) {
    flex-direction: column;
    /* padding: 10px 5px ; */
    img {
      max-width: 100%;
    }
  }
}
.fifth_card__pizza_menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  img {
    max-width: 800px;
    border-radius: 8px;
  }
  /* background-color: $background-dark; */
  position: relative;
  /* color: white; */
  padding: 15px 10px ;
  border-radius: 8px;

  @media only screen and (max-width: 856px) {
    flex-direction: column;
    padding: 10px 5px ;
  }
}
.fifth_card_cocktails_animation_show {
  animation-name: secondCardAnimationShowSecondRow;
  animation-duration: 0.8s;
}
.fifth_card_cocktails_animation_hide {
  animation-name: secondCardAnimationHideSecondRow;
  animation-duration: 0.8s;
  right: -100vw;
}
.fifth_card_pizza_animation_show {
  animation-name: secondCardAnimationShowSecondRow;
  animation-duration: 0.8s;
}
.fifth_card_pizza_animation_hide {
  animation-name: secondCardAnimationHideSecondRow;
  animation-duration: 0.8s;
  right: -100vw;
}
.fifth_card__row_two {
  display: flex;
  flex-direction: row;
  background-color: $background-dark;
  position: relative;
  color: white;
  padding: 15px 10px ;
  border-radius: 8px;

  @media only screen and (max-width: 856px) {
    flex-direction: column;
    padding: 10px 5px ;
  }

}

.fifth_card__row_two_col_one {
  width: 100%;
}

.fifth_card__row_two_col_two {
  width: 100%;
}


.sixth_card {
  width: 70vw;
  @media only screen and (max-width: 856px) {
    width: 100vw;
  }
}

.sixth_card__row_one {
  display: flex;
  position: relative;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 5%;
}
.shop_element {
  cursor: default;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // background-color: $background-dark;
  border-radius: 8px;
  // color: white;
  color: $text-dark;
  margin-top: 15px;
  padding: 15px;
  text-align: center;
  @media only screen and (max-width: 856px) {
    width: 70%;
    flex-wrap: nowrap;
  }
  div {
    width: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  h1 {
    text-transform: uppercase;
    font-family: 'Righteous';
    font-weight: 400;
    font-size: 22px;
  }
  h2 {
    font-family: 'Righteous';
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
}
.gallery_card {
  display: flex;
  width: 100vw;
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: 50px;
  padding: 15px;
  &::-webkit-scrollbar{
    display: none;
  }
}
.gallery_card__content {
  min-height: 70vh !important;

}
.footer__content {
  min-height: 60vh !important;
  height: auto;
  width: 100%;
}
.gallery_element {
  cursor: pointer ;
  padding: 15px;
  height: 400px;
  position: relative;
  width: 280px;
  border-radius: 8px;
  transition: 0.3s;
  img {
    cursor: pointer;
  }
  &:hover {
    // transform: rotate(2deg);
  }
  @media only screen and (max-width: 856px) {
    &:hover {
      transform: rotate(0deg);
    }
  }
  img {
      height:100%;
      border-radius: 8px;
      object-fit: cover;
      overflow: hidden;
      width: 280px; /* or any custom size */
  }
}
.staff_card {
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: 50px;
  padding: 15px;
  position: relative;
  &::-webkit-scrollbar{
    display: none;
  }
}
.staff_card__management {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 15px;
  height: 400px;
  gap: 15px;
  img {
    height:100%;
    border-radius: 8px;
    object-fit: cover;
    overflow: hidden;
    width: 280px; /* or any custom size */
  }
  @media only screen and (max-width: 856px) {
    /* flex-direction: column;
    align-items: center;
    height: 800px; */
    gap: 5px;
  }
}

.staff_card__row_two_animation_show {
  animation-name: secondCardAnimationShowSecondRow;
  animation-duration: 0.8s;
}
.staff_card__row_two_animation_hide {
  animation-name: secondCardAnimationHideSecondRow;
  animation-duration: 0.8s;
  right: -100vw;
}

.staff_card__employees {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 15px;
  height: 400px;
  gap: 15px;
  img {
    height:100%;
    border-radius: 8px;
    object-fit: cover;
    overflow: hidden;
    width: 280px; /* or any custom size */
  }
  @media only screen and (max-width: 856px) {
    /* flex-direction: column;
    align-items: center;
    height: 800px; */
    gap: 5px;
  }
}

.staff_element {
  cursor: default;
  padding: 15px;
  height: 400px;
  position: relative;
  width: 280px;
  border-radius: 8px;
  transition: 0.3s;

  /* &:hover {
    transform: rotate(2deg);
  } */
  /* @media only screen and (max-width: 856px) {
    &:hover {
      transform: rotate(0deg);
    }
  } */
  img {
      height:100%;
      border-radius: 8px;
      object-fit: cover;
      overflow: hidden;
      width: 280px; /* or any custom size */
  }
  /* &:hover > .staff_hover {

    height: 50px;

    h4 {
      display: block;
    }
  } */
}

.staff_hover {
  position: absolute;
  bottom: -10px;
  width: 280px;
  height: 0px;
  /* display: flex;
  align-content: flex-start; */

  display: flex;
  align-items: center;

  border-radius: 0px 0px 8px 8px;
  background-color: $text-dark-brown;
  color: white;
  padding: 0px 0px;
  transition: 0.5s;
  h4 {
    padding: 0px 15px;
  }

}

.gallery_img_wrap {
  overflow: hidden;
  cursor: default;
  padding: 0px;
  height: 400px;
  position: relative;
  width: 280px;
  border-radius: 8px;
  transition: 0.3s;
  img {
    transition: 0.3s;
    height:100%;
    border-radius: 8px;
    object-fit: cover;
    overflow: hidden;
    width: 280px; /* or any custom size */
  }
  &:hover {
    img {
      filter: brightness(80%);
    }
  }
  &:hover > .staff_hover {
    bottom: 0px;
    height: 50px;

    h4 {
      display: block;
    }
  }
}
.cursor_pointer {
  cursor: pointer;
}
.event_card {
  padding: 15px;
  width: 80vw;
  display: grid;
  .event_card_more_content {
    font-family: 'Righteous';
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    transition: 0.3s;

    @media only screen and (max-width: 856px) {
      gap: 10px;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
  div:not(.event_card_row_one):not(.event_card_row_two) {
    padding: 15px;
  }
  img {
    height:100%;
    border-radius: 8px;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
  }
}

.event_card_row_one {
  display: grid;
  position: relative;
  div:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  div:nth-child(2) {
    grid-column-start: 2;
    grid-column-end: 2;
    @media only screen and (max-width: 856px) {
      grid-column-start: 1;
      grid-column-end: 1
    }
  }
}

.event_card_row_two {
  display: grid;
  position: relative;
  div:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    @media only screen and (max-width: 856px) {
      grid-column-start: 1;
      grid-column-end: 1
    }
  }
  div:nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 3;
    @media only screen and (max-width: 856px) {
      grid-column-start: 1;
      grid-column-end: 1
    }
  }
  div:nth-child(3) {
    grid-column-start: 3;
    grid-column-end: 4;
    @media only screen and (max-width: 856px) {
      grid-column-start: 1;
      grid-column-end: 1
    }
  }
}

.bvdLogoStyle {
  position: absolute;
  border-radius: 8px;
  bottom: -110px;
  left: 194px;
  @media only screen and (max-width: 856px) {

    position: relative;
    bottom: 0px;
    left: 0px;
    margin-top: -110px;
  }
}

.vlcieLogoStyle {
  width: 200px !important;
  z-index: 50 !important;
  position: absolute !important;
  border-radius: 8px !important;
  bottom: -90px !important;
  right: 40px !important;
  @media only screen and (max-width: 856px) {

    position: relative !important;
    bottom: 0px !important;
    right: 0px !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: -60px !important;
  }
}

.coffeinLogoStyle {
  width: 200px !important;
  z-index: 50;
  position: absolute;
  border-radius: 8px;
  bottom: -40px;
  left: 230px;
  @media only screen and (max-width: 856px) {

    position: relative !important;
    bottom: 0px !important;
    left: 25% !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: -60px !important;
  }
}