@keyframes scaleUp {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes scaleDown {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

@keyframes rollUp {
  from {
    top: 25%;
  }
  to {
    top: -200%;
  }
}
@keyframes rollDown {
  from {
    top: -300%;
  }
  to {
    top: 25%;
  }
}

@keyframes menuOpen {
  from {
    bottom: 0px;
  }
  to {
    bottom: 100px;
  }
}
@keyframes menuClose {
  from {
    bottom: 100px;
  }
  to {
    bottom: 0px;
  }
}

@keyframes rollFromLeft {
  from {
    position: relative;
    left: -800px;
  }
  to {
    position: relative;
    left: 0px;
  }
}

@keyframes rollFromRight {
  from {
    position: relative;
    right: -500px;
  }
  to {
    position: relative;
    right: 0px;
  }
}

@keyframes noAnimation {
  100% {
    left: 0px
  }
}

@keyframes hamburgerAnimation {
  from {
    right: -100px;
  }
  to {
    right: 0px;
  }
}

@keyframes slideOut {
  from {
    right: -50px;
  }
  to {
    right: 0px;
  }
}
@keyframes slideOut {
  from {
    right: 0px;
  }
  to {
    right: -100px;
  }
}


@keyframes galleryImageAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes secondCardAnimationShow {
  from {
    left: -100vw;
  }
  to {
    left: 0px
  }
}
@keyframes secondCardAnimationHide {
  from {
    left: 0px;
  }
  to {
    left: -100vw
  }
}


@keyframes secondCardAnimationShowSecondRow {
  from {
    right: -100vw;
  }
  to {
    right: 0px
  }
}
@keyframes secondCardAnimationHideSecondRow {
  from {
    right: 0px;
  }
  to {
    right: -100vw
  }
}


/* @keyframes secondCardAnimationShowFifthCard {
  from {
    right: -100vw;
  }
  to {
    right: 0px
  }
}
@keyframes secondCardAnimationHideFifthCard {
  from {
    right: 0px;
  }
  to {
    right: -100vw
  }
} */